
import { mapState, mapWritableState } from 'pinia';
import { defineComponent } from 'vue';
import { IMember } from '../../../entities/member';
import { getMember, kickMember, updateMemberPermissions } from '@/services/steamcord/orgs';
import { useAuthStore } from '@/store/authStore';
import { useMainStore } from '@/store/mainStore';
import { useOrgStore } from '@/store/orgStore';
import { getRandomInt } from '@/util/getRandomInt';
import { formatDate } from '@/util/formatDate';
import DropdownButton from '@/components/buttons/DropdownButton.vue';
import Modal from '@/components/Modal.vue';
import PermissionsSwitches from '@/components/dashboard/PermissionsSwitches.vue';
import SavePopup from '@/components/dashboard/SavePopup.vue';

export default defineComponent({
  async beforeRouteEnter(to, from, next) {
    const { currentOrg } = useOrgStore();
    if (!currentOrg) {
      next({ name: 'Internal Server Error' });
      return;
    }

    const memberID = to.params.id.toString();
    const { customerID } = useAuthStore();

    if (memberID === customerID) {
      next({ name: 'Not Found' });
      return;
    }

    const member = await getMember(currentOrg.id, memberID);
    if (!member) {
      next({ name: 'Not Found' });
      return;
    }

    next((vm) => {
      (vm as any).setMember(member);
    });
  },
  components: {
    DropdownButton,
    Modal,
    PermissionsSwitches,
    SavePopup,
  },
  computed: {
    ...mapState(useOrgStore, ['currentOrg']),
    ...mapWritableState(useMainStore, {
      appLoading: 'loading',
    }),
    formattedDate(): string | undefined {
      if (!this.member) {
        return undefined;
      }

      return formatDate(this.member.createdDate as Date);
    },
    memberChanged(): boolean {
      return !!this.original && JSON.stringify(this.member) !== this.original;
    },
  },
  data() {
    return {
      kickLoading: false,
      loading: false,
      member: undefined as IMember | undefined,
      original: '',
      showDeleteModal: false,
    };
  },
  methods: {
    getRandomInt(min: number, max: number) {
      return getRandomInt(min, max);
    },
    async kickMember() {
      if (!this.member || this.kickLoading || !this.currentOrg) {
        return;
      }

      this.kickLoading = true;

      await kickMember(this.currentOrg.id, this.member.id);

      await this.$router.replace({
        name: 'Members',
        params: { subdomain: this.$route.params.subdomain },
      });
    },
    async onSubmit() {
      if (!this.member || this.loading || !this.currentOrg) {
        return;
      }

      this.loading = true;

      await updateMemberPermissions(
        this.currentOrg.id,
        this.member.id,
        this.member.permissions,
      );

      this.original = JSON.stringify(this.member);

      this.loading = false;
    },
    setMember(member: IMember) {
      this.member = member;
      this.original = JSON.stringify(member);
    },
  },
  mounted() {
    this.appLoading = false;
  },
});
